import React from 'react'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'

// https://www.npmjs.com/package/react-responsive-carousel
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const IndexTop = () => {
	const data = useStaticQuery(
		graphql`
			query {
				buki: file(relativePath: {eq: "buki.jpg"}) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				aikido: file(relativePath: {eq: "aikido.png"}) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				exercise: file(relativePath: {eq: "exercise.jpg"}) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				enbu: file(relativePath: {eq: "enbu.jpg"}) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	)

	return (
		<div className='index-top'>
			<Carousel
				autoPlay
				showStatus={false}
				showThumbs={false}
				infiniteLoop={true}
				interval={3000}
				className='top-carousel'>
				<div>
					<Img fluid={data.aikido.childImageSharp.fluid} alt='aikido' />
				</div>
				<div>
					<Img fluid={data.buki.childImageSharp.fluid} alt='buki' />
				</div>
				<div>
					<Img fluid={data.exercise.childImageSharp.fluid} alt='iaido' />
				</div>
				<div>
					<Img fluid={data.enbu.childImageSharp.fluid} alt='enbu' />
				</div>
			</Carousel>
		</div>
	)
}

export default IndexTop

// import React from 'react'
// import Img from 'gatsby-image'
// import {useStaticQuery, graphql} from 'gatsby'

// // https://www.npmjs.com/package/react-responsive-carousel
// import {Carousel} from 'react-responsive-carousel'
// import 'react-responsive-carousel/lib/styles/carousel.min.css'

// const IndexTop = () => {
// 	const data = useStaticQuery(
// 		graphql`
// 			query {
// 				buki: file(relativePath: {eq: "buki.jpg"}) {
// 					childImageSharp {
// 						fixed(height: 400) {
// 							...GatsbyImageSharpFixed
// 						}
// 					}
// 				}
// 				aikido: file(relativePath: {eq: "aikido.png"}) {
// 					childImageSharp {
// 						fixed(height: 400) {
// 							...GatsbyImageSharpFixed
// 						}
// 					}
// 				}
// 				exercise: file(relativePath: {eq: "exercise.jpg"}) {
// 					childImageSharp {
// 						fixed(height: 400) {
// 							...GatsbyImageSharpFixed
// 						}
// 					}
// 				}
// 				enbu: file(relativePath: {eq: "enbu.jpg"}) {
// 					childImageSharp {
// 						fixed(height: 400) {
// 							...GatsbyImageSharpFixed
// 						}
// 					}
// 				}
// 			}
// 		`
// 	)

// 	return (
// 		<div className='index-top'>
// 			<Carousel
// 				autoPlay
// 				showStatus={false}
// 				showThumbs={false}
// 				infiniteLoop={true}
// 				className='top-carousel'>
// 				<div>
// 					<Img fixed={data.aikido.childImageSharp.fixed} alt='aikido' />
// 				</div>
// 				<div>
// 					<Img fixed={data.buki.childImageSharp.fixed} alt='buki' />
// 				</div>
// 				<div>
// 					<Img fixed={data.exercise.childImageSharp.fixed} alt='iaido' />
// 				</div>
// 				<div>
// 					<Img fixed={data.enbu.childImageSharp.fixed} alt='enbu' />
// 				</div>
// 			</Carousel>
// 		</div>
// 	)
// }

// export default IndexTop
