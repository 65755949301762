import * as React from 'react'
import Img from 'gatsby-image'
import {graphql, Link} from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/layout'
import IndexTop from '../components/indexTop'

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo />
			<IndexTop />
			<div className='container index-page'>
				<section className='page-section'>
					<h1>تعريف عن أيكي إشِّين-ريو</h1>
					<p>
						أيكي إشِّين-ريو، أو مدرسة أيكي إشِّين، هي مدرسة فنون دفاع عن النفس
						أسسها يوشيناكا سوزوكي عام ٢٠١٢م، والذي تدرب على طريقة ناكامورا
						بمدرسة تاكيدا لسنوات عديدة. فن الدفاع عن النفس هذا موروث عن
						الـ"أيكي" القديمة التي كانت تستخدم بشكل أساسي في فنون المبارزة
						بالسيف في عصر ميجي والفنون القتالية القديمة الجيدة بإضافة ​​تقنيات
						جديدة إليها، ولذا يطلق على هذا الفن القتالي اسم أيكي إشِّين من منطلق
						"أخذ خطوة للأمام".
					</p>
				</section>
				<section className='page-section'>
					<h2>حول المؤسس يوشيناكا سوزوكي</h2>
					<div className='sokeProfile-pic'>
						<Img fluid={data.sokeProfile.childImageSharp.fluid} />
					</div>
					<ul className='soke-title'>
						<li>المؤسس لمدرسة أيكي إشِّين</li>
						<li>معلم رئيسي في المنظمة غير الربحية لجمعية الأيكيدو الدولية</li>
						<li>قائد رياضي معتمد من جمعية التربية البدنية اليابانية</li>
						<li>مدرب رئيسي بجمعية تدريب المعلمين اليابانية</li>
						<li>معلم سابق عالي المرتبة على طريقة ناكامورا بمدرسة تاكيدا</li>
						<li>عضو فخري في الاتحاد الدولي للفنون القتالية التقليدية</li>
					</ul>
					<p>
						منذ الطفولة، درس الفنون القتالية تحت إشراف المعلم الخبير ماسايوكي
						تويوشيما في ساكادو سوبوكان (اسم صالة تدريب)، وتدرب على طريقة
						ناكامورا بمدرسة تاكيدا لسنوات عديدة، وأسس مدرسة أيكي إشِّين.
					</p>
				</section>
				<section className='page-section'>
					<h2>محتوى التدريب لأيكي إشِّين</h2>
					<p>
						في أيكي إشِّين، تتم ممارسة التدريب على فنون الدفاع عن النفس على
						طريقة مدرسة تاكيدا وطريقة ناكامورا بمدرسة تاكيدا. يتضمن التدريب ٦
						تقنيات، وهي أيكيدو، إيايدو، جيو جيتسو كينبو(الفن القتالي بدون
						أسلحة)، جو جيتسو (الفن القتالي باستخدام العصي)، سوفو جيتسو (الفن
						القتالي باستخدام العصي القصيرة)، وشوريكين جيتسو (الفن القتالي برمي
						السكاكين).
					</p>
					<div className='contents-section'>
						<div className='content'>
							<div className='content-img'>
								<Img fluid={data.aikidoContents.childImageSharp.fluid} />
							</div>
							<div className='content-p'>
								<h3>أيكيدو</h3>
								<p>
									أيكيدو هو نوع من الجوجتسو يستخدم فن الأيكي. في مدرستنا، نمارس
									مجموعة متنوعة من التقنيات بناءً على ٣٦ تقنية أساسية مثل أتيمي
									وازا (تقنية الضرب)، كوتي وازا (تقنية باليد)، كوشي وازا (تقنية
									بالخصر)، ونيغاتامي وازا (تقنية على الأرض أو خلال الانبطاح).
									نوصي بطريقتنا بشكل خاص للمبتدئين، حيث ستتعلم الأساسيات
									المشتركة لجميع التقنيات الأخرى.
								</p>
							</div>
						</div>
						<div className='content content-iaido'>
							<div className='content-img'>
								<Img fluid={data.iaidoContents.childImageSharp.fluid} />
							</div>
							<div className='content-p'>
								<h3>إيايدو</h3>
								<p>
									الإيايدو هو فن قتالي يتمثل في "براعة امتشاق السيف"، وهي تقنية
									إزالة السيف من الغمد في توقيت مناسب أمام العدو. من خلال تعلم
									كيفية التعامل مع السيوف والإيايدو، سوف تعمق فهمك للأيكيدو.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className='page-section access-section'>
					<h2>Access & Contact</h2>
					<h3>
						Aiki Ishinryu Tokyo Honbu dojo / الصالة الرئيسية بمقر أيكي إشِّين في
						طوكيو
					</h3>
					<p>Kotakecho 2-8-8 Nerimaku Tokyo 〒1760004 </p>
					<p>
						<a href='mailto:aikiishinryu21@gmail.com'>
							aikiishinryu21@gmail.com
						</a>
					</p>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.3201821983516!2d139.67661701526046!3d35.74293428017991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed38793779b1%3A0xe91692056e5dc964!2zTlBP5rOV5Lq644Kk44Oz44K_44O844OK44K344On44OK44Or5ZCI5rCX6YGT5Y2U5Lya!5e0!3m2!1sja!2sjp!4v1594990841295!5m2!1sja!2sjp'
						allowFullScreen
						aria-hidden='false'
						title='本部道場所在地'
					/>
				</section>
			</div>
			<section className='page-section'>
				<p className='center'>
					<a
						href='https://www.facebook.com/sakuradatescafe'
						className='translatorLink'>
						ترجمة من اللغة اليابانية إلى اللغة العربية: مقهى التمور
					</a>
				</p>
			</section>
		</Layout>
	)
}

export const query = graphql`
	query {
		sokeProfile: file(relativePath: {eq: "sokeProfileZoom.png"}) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		aikidoContents: file(relativePath: {eq: "aikido.png"}) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		iaidoContents: file(relativePath: {eq: "iaido.jpg"}) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default IndexPage
